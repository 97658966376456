<template>
  <component
    :is="tag"
    class="a-text"
    :class="[
      uppercase && 'uppercase',
      disabled && 'disabled',
      className,
      weight,
    ]"
    :style="[
      fontFamily === 'vk' && 'font-family: var(--vk-font)',
    ]"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
interface Props {
  tag?: 'h1' | 'h2' | 'h3' | 'p' | 'span' | 'div' | 'a',
  sizeLg?: {size: number, line: number},
  sizeSm?: {size: number, line: number},
  weight?: 'extra-bold' | 'bold' | 'regular',
  fontFamily?: 'gorizont' | 'vk',
  uppercase?: boolean,
  className?: string,
  disabled?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
  className: '',
  sizeLg: { size: 40, line: 48 },
  sizeSm: { size: 24, line: 32 },
  weight: 'extra-bold',
  fontFamily: 'gorizont',
  uppercase: true,
  disabled: false,
});

const desktopSize = props.sizeLg.size + 'px';
const desktopLineHeight = props.sizeLg.line + 'px';
const mobileSize = props.sizeSm.size + 'px';
const mobileLineHeight = props.sizeSm.line + 'px';

</script>

<style scoped lang="postcss">
.a-text {
  font-family: var(--gorizont-font);

  font-size: v-bind(desktopSize);
  line-height: v-bind(desktopLineHeight);
  color: inherit;

  @media screen and (max-width: 767px) {
    font-size: v-bind(mobileSize);
    line-height: v-bind(mobileLineHeight);
  }

  &.regular {
    font-weight: 500;
  }
  &.bold {
    font-weight: 700;
  }
  &.extra-bold {
    font-weight: 900;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
